import React, { Suspense } from "react";
import styles from "./PreLogin.module.scss";
// import Prelogin from "./../../assets/Images/prelogin-background.svg";
import { Outlet } from "react-router-dom";
import Prelogin from "./../../assets/Images/PreLogin.png";

const PreLogin = ({ children }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftSide}>
        {/* <span className={styles.heading}>
          Hello,
          <span>Welcome!!</span>
        </span> */}
        <img src={Prelogin} class={styles.responsive} />
      </div>
      <span className={styles.rightSide}>
        <Suspense fallback=''>
          <Outlet />
        </Suspense>
      </span>
    </div>
  );
};

export default PreLogin;
