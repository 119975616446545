import React from 'react'
import styles from "./Loader.module.scss"

const Loader = () => {
  return (
    <span class={styles.loader}></span>
  )
}

export default Loader
